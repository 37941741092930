<template>
  <div>
    <v-form ref="form">
      <v-alert type="error" text v-if="error">
        {{ error }}
      </v-alert>
      <v-textarea
        label="Coordinates"
        hide="Enter coordinates separated by comma"
        placeholder="-70.068552, 42.029477"
        rows="1"
        auto-grow
        :rules="coordinateRules"
        v-model="coordinates"
      ></v-textarea>
      <v-btn color="primary" class="text-capitalize" @click="add()">
        {{ buttonLabel }}
      </v-btn>
      <v-btn
        v-show="paste"
        color="success ml-1 text-capitalize"
        @click="pasteAndAdd()"
      >
        Paste & Add
      </v-btn>
      <v-btn class="ml-1 text-capitalize" dark color="grey" @click="cancel()"
        >Cancel</v-btn
      >
    </v-form>
  </div>
</template>

<script>
import { v4 } from 'uuid';
import chroma from 'chroma-js';
import { parseCoordinates } from '../utils';

const validCoordinates = (v) => {
  try {
    const splitted = v.split('\n');
    const valid = splitted.every(
      (i) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        i.split(',').length === 2 && i.split(',').every((coor) => coor.trim()),
    );
    return valid;
  } catch {
    return false;
  }
};

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    buttonLabel: {
      type: String,
      default: 'Add',
    },
    paste: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: '',
      coordinates: '',
      parsedValue: [],
      coordinateRules: [
        (v) => validCoordinates(v) || 'Enter coordinates separated by comma',
      ],
    };
  },
  watch: {
    value: {
      handler(value) {
        if (value && typeof value === 'object') {
          this.coordinates = `${value.lat}, ${value.lng}`;
        }
      },
      immediate: true,
    },
    coordinates: {
      handler(value) {
        try {
          this.parsedValue = parseCoordinates(value);
        } catch (err) {
          this.parsedValue = [];
          console.warn(err);
        }
      },
    },
  },
  methods: {
    async pasteAndAdd() {
      try {
        let value;
        let geojson;
        const copiedText = await navigator.clipboard.readText();
        try {
          geojson = JSON.parse(copiedText);
        } catch {
          value = copiedText;
        }

        if (geojson) {
          const id = v4();
          const color = chroma.random().darken().hex();

          if (geojson.type === 'FeatureCollection') {
            [geojson] = geojson.features;
          }
          if (!geojson) {
            return;
          }
          let coordinates;
          const geometryType = geojson.geometry.type;
          switch (geometryType) {
            case 'Polygon':
              [coordinates] = geojson.geometry.coordinates;
              coordinates.splice(coordinates.length - 1, 1);
              break;
            case 'Point':
              coordinates = [geojson.geometry.coordinates];
              break;
            case 'LineString':
              coordinates = geojson.geometry.coordinates;
              break;
            default:
              break;
          }
          if (!coordinates && !Array.isArray(coordinates)) {
            return;
          }
          coordinates.forEach(([lng, lat]) => {
            this.emit({ lng, lat }, id, color);
          });
        } else if (value) {
          this.emit(value);
        }
      } catch (error) {
        this.error = error;
      }
    },
    emit(str, id, color) {
      if (typeof str === 'object') {
        this.parsedValue.push({ ...str, id, color });
      } else {
        try {
          this.parsedValue = parseCoordinates(str);
        } catch (error) {
          console.warn(error);
          //
        }
      }
      if (this.parsedValue) {
        this.parsedValue.forEach((coor) => {
          this.$emit('add', coor);
        });
      }
    },

    add() {
      this.emit(this.coordinates);
    },
    cancel() {
      this.reset();
      this.$emit('cancel');
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style>
</style>
